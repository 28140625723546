import React from 'react';
import {Box, Button, Card, CardContent, Divider, Grid, Typography} from '@mui/material';
import {Link} from "gatsby";
import BaseTooltip from "../components/utils/BaseTooltip";
import Layout from "./layout";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BasePageContent from "../components/utils/BasePageContent";

const concatAddress = (data) => {
  let address = `${data.street} ${data.propertyNo}`;

  if (data.apartmentNo) address += ` / ${data.apartmentNo}`;

  address += `, ${data.postalCode} ${data.city}`;
  address += `, ${data.country || 'Polska'}`;
  return address;
}

const formatIban = iban => {
  return iban.slice(0, 4).replace(/(.{2})/g, "$1 ") + iban.slice(4).replace(/(.{4})/g, "$1 ");
}

// noinspection JSValidateTypes
const DetailRow = ({name, value}) => (
  <Grid container item>
    <Grid item xs={12}>
      <Typography color="textSecondary">
        {name}
      </Typography>
    </Grid>
    {((Array.isArray(value) ? value : [value]).map(val => (
      <Grid item xs={12}>
        <Typography>
          {val}
        </Typography>
      </Grid>
    )))}
  </Grid>
)

const CompanyCard = ({pageContext: {companyData}}) => (
  <Layout >
    <BasePageContent>
      <Box my={2}>
        <Link to='/firmy'>
          <Button startIcon={<ArrowBackIcon/>} variant='outlined'>Powrót do listy</Button>
        </Link>
      </Box>
      <Card variant='outlined' sx={{minWidth: 275}}>
        <Box
          sx={{
            background: '#fafafa',
            color: '#f59e0b',
            fontWeight: 800,
            padding: 8,
          }}
          textAlign={'center'}
        >
          <Typography>
            KARTA PODMIOTU
          </Typography>
        </Box>
        <CardContent>
          <Box my={2}>
            <Grid container>
              <Grid item xs={6}>
                <DetailRow name='Nazwa pełna' value={<Typography variant="h6" component="h2">
                  {companyData.name}
                </Typography>}/>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {companyData.logoUrl &&
                  <img src={companyData.logoUrl} alt="Logo podmiotu" width={160} placeholder="none"/>
                }
              </Grid>
            </Grid>
          </Box>
          <Divider/>
          <Box my={2}>
            <Grid container spacing={3}>
              <Grid container item xs={3}>
                <DetailRow name='NIP' value={companyData.nip}/>
              </Grid>
              <Grid container item xs={3}>
                <DetailRow name='REGON' value={companyData.regon}/>
              </Grid>
            </Grid>
          </Box>
          <Divider/>
          <Box my={2}>
            <Grid container item spacing={3}>
              <DetailRow
                name='Adres siedziby'
                value={concatAddress(companyData)}
              />
              <DetailRow
                name='Działalność przeważająca (wg PKD)'
                value={
                  <BaseTooltip
                    title='Wyszukaj podmioty realizujące działalność o tym samym kodzie PKD'
                    placement="top-end"
                    arrow
                  >
                    <Grid xs={6} item>
                      <Link to={`/firmy?pkd=${companyData.mainPkd}`}>
                        <p style={{cursor: 'pointer'}} className='link-inline'>
                          {`${companyData.mainPkdName} (${companyData.mainPkd})`}
                        </p>
                      </Link>
                    </Grid>
                  </BaseTooltip>
                }
              />
              <DetailRow
                name='Forma prawna'
                value={companyData.legalForm}
              />
              <DetailRow
                name='Data rozpoczęcia działalonści'
                value={companyData.activeFrom}
              />
            </Grid>
          </Box>
          <Divider/>
          <Box my={2}>
            <Grid container>
              <Grid xs={6} container item>
                <Grid container item spacing={3}>
                  <DetailRow
                    name='Status podatnika VAT'
                    value='Czynny'
                  />
                  <DetailRow
                    name='Numery rachunków rozliczeniowych lub imiennych rachunków w SKOK'
                    value={companyData.bankAccounts.map(formatIban)}
                  />
                </Grid>

              </Grid>
              <Grid
                xs={6}
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <BaseTooltip title="Funkcjonalność dostępna wkrótce" placement="top-start" arrow>
                  <span>
                    <Button
                      disableElevation
                      disabled={true}
                      variant='contained'
                      size='large'
                      color='primary'
                    >
                      Utwórz płatność na rzecz podmiotu
                    </Button>
                  </span>
                </BaseTooltip>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </BasePageContent>
  </Layout>
)


export const Head = ({ pageContext: { companyData } }) => (
  <title>{`Lista podmiotów | ${companyData.name} | Zapłatomat`}</title>
);


export default CompanyCard;
